.footer {
    position: relative;
    height: 1000px;

    &__wave {
        position: absolute;
        width: 100%;
        top: 30px;
        left: 0;
        z-index: 1;

        svg {
            width: 100%;
        }
    }
}

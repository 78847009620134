/* montserrat-100 - latin_cyrillic */
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 100;
    src: url("montserrat-v15-latin_cyrillic-100.eot"); /* IE9 Compat Modes */
    src: local("Montserrat Thin"), local("Montserrat-Thin"),
      url("montserrat-v15-latin_cyrillic-100.eot?#iefix") format("embedded-opentype"),
      /* IE6-IE8 */ url("montserrat-v15-latin_cyrillic-100.woff2") format("woff2"),
      /* Super Modern Browsers */ url("montserrat-v15-latin_cyrillic-100.woff") format("woff"),
      /* Modern Browsers */ url("montserrat-v15-latin_cyrillic-100.ttf") format("truetype"),
      /* Safari, Android, iOS */ url("montserrat-v15-latin_cyrillic-100.svg#Montserrat")
        format("svg"); /* Legacy iOS */
  }
  /* montserrat-200 - latin_cyrillic */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 200;
    src: url("montserrat-v15-latin_cyrillic-200.eot"); /* IE9 Compat Modes */
    src: local("Montserrat ExtraLight"), local("Montserrat-ExtraLight"),
      url("montserrat-v15-latin_cyrillic-200.eot?#iefix") format("embedded-opentype"),
      /* IE6-IE8 */ url("montserrat-v15-latin_cyrillic-200.woff2") format("woff2"),
      /* Super Modern Browsers */ url("montserrat-v15-latin_cyrillic-200.woff") format("woff"),
      /* Modern Browsers */ url("montserrat-v15-latin_cyrillic-200.ttf") format("truetype"),
      /* Safari, Android, iOS */ url("montserrat-v15-latin_cyrillic-200.svg#Montserrat")
        format("svg"); /* Legacy iOS */
  }
  /* montserrat-300 - latin_cyrillic */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    src: url("montserrat-v15-latin_cyrillic-300.eot"); /* IE9 Compat Modes */
    src: local("Montserrat Light"), local("Montserrat-Light"),
      url("montserrat-v15-latin_cyrillic-300.eot?#iefix") format("embedded-opentype"),
      /* IE6-IE8 */ url("montserrat-v15-latin_cyrillic-300.woff2") format("woff2"),
      /* Super Modern Browsers */ url("montserrat-v15-latin_cyrillic-300.woff") format("woff"),
      /* Modern Browsers */ url("montserrat-v15-latin_cyrillic-300.ttf") format("truetype"),
      /* Safari, Android, iOS */ url("montserrat-v15-latin_cyrillic-300.svg#Montserrat")
        format("svg"); /* Legacy iOS */
  }
  /* montserrat-500 - latin_cyrillic */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    src: url("montserrat-v15-latin_cyrillic-500.eot"); /* IE9 Compat Modes */
    src: local("Montserrat Medium"), local("Montserrat-Medium"),
      url("montserrat-v15-latin_cyrillic-500.eot?#iefix") format("embedded-opentype"),
      /* IE6-IE8 */ url("montserrat-v15-latin_cyrillic-500.woff2") format("woff2"),
      /* Super Modern Browsers */ url("montserrat-v15-latin_cyrillic-500.woff") format("woff"),
      /* Modern Browsers */ url("montserrat-v15-latin_cyrillic-500.ttf") format("truetype"),
      /* Safari, Android, iOS */ url("montserrat-v15-latin_cyrillic-500.svg#Montserrat")
        format("svg"); /* Legacy iOS */
  }
  /* montserrat-regular - latin_cyrillic */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: url("montserrat-v15-latin_cyrillic-regular.eot"); /* IE9 Compat Modes */
    src: local("Montserrat Regular"), local("Montserrat-Regular"),
      url("montserrat-v15-latin_cyrillic-regular.eot?#iefix") format("embedded-opentype"),
      /* IE6-IE8 */ url("montserrat-v15-latin_cyrillic-regular.woff2") format("woff2"),
      /* Super Modern Browsers */ url("montserrat-v15-latin_cyrillic-regular.woff")
        format("woff"),
      /* Modern Browsers */ url("montserrat-v15-latin_cyrillic-regular.ttf") format("truetype"),
      /* Safari, Android, iOS */ url("montserrat-v15-latin_cyrillic-regular.svg#Montserrat")
        format("svg"); /* Legacy iOS */
  }
  /* montserrat-600 - latin_cyrillic */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    src: url("montserrat-v15-latin_cyrillic-600.eot"); /* IE9 Compat Modes */
    src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"),
      url("montserrat-v15-latin_cyrillic-600.eot?#iefix") format("embedded-opentype"),
      /* IE6-IE8 */ url("montserrat-v15-latin_cyrillic-600.woff2") format("woff2"),
      /* Super Modern Browsers */ url("montserrat-v15-latin_cyrillic-600.woff") format("woff"),
      /* Modern Browsers */ url("montserrat-v15-latin_cyrillic-600.ttf") format("truetype"),
      /* Safari, Android, iOS */ url("montserrat-v15-latin_cyrillic-600.svg#Montserrat")
        format("svg"); /* Legacy iOS */
  }
  /* montserrat-700 - latin_cyrillic */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    src: url("montserrat-v15-latin_cyrillic-700.eot"); /* IE9 Compat Modes */
    src: local("Montserrat Bold"), local("Montserrat-Bold"),
      url("montserrat-v15-latin_cyrillic-700.eot?#iefix") format("embedded-opentype"),
      /* IE6-IE8 */ url("montserrat-v15-latin_cyrillic-700.woff2") format("woff2"),
      /* Super Modern Browsers */ url("montserrat-v15-latin_cyrillic-700.woff") format("woff"),
      /* Modern Browsers */ url("montserrat-v15-latin_cyrillic-700.ttf") format("truetype"),
      /* Safari, Android, iOS */ url("montserrat-v15-latin_cyrillic-700.svg#Montserrat")
        format("svg"); /* Legacy iOS */
  }
  /* montserrat-800 - latin_cyrillic */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    src: url("montserrat-v15-latin_cyrillic-800.eot"); /* IE9 Compat Modes */
    src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"),
      url("montserrat-v15-latin_cyrillic-800.eot?#iefix") format("embedded-opentype"),
      /* IE6-IE8 */ url("montserrat-v15-latin_cyrillic-800.woff2") format("woff2"),
      /* Super Modern Browsers */ url("montserrat-v15-latin_cyrillic-800.woff") format("woff"),
      /* Modern Browsers */ url("montserrat-v15-latin_cyrillic-800.ttf") format("truetype"),
      /* Safari, Android, iOS */ url("montserrat-v15-latin_cyrillic-800.svg#Montserrat")
        format("svg"); /* Legacy iOS */
  }
  /* montserrat-900 - latin_cyrillic */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 900;
    src: url("montserrat-v15-latin_cyrillic-900.eot"); /* IE9 Compat Modes */
    src: local("Montserrat Black"), local("Montserrat-Black"),
      url("montserrat-v15-latin_cyrillic-900.eot?#iefix") format("embedded-opentype"),
      /* IE6-IE8 */ url("montserrat-v15-latin_cyrillic-900.woff2") format("woff2"),
      /* Super Modern Browsers */ url("montserrat-v15-latin_cyrillic-900.woff") format("woff"),
      /* Modern Browsers */ url("montserrat-v15-latin_cyrillic-900.ttf") format("truetype"),
      /* Safari, Android, iOS */ url("montserrat-v15-latin_cyrillic-900.svg#Montserrat")
        format("svg"); /* Legacy iOS */
  }
  
.home-projects {
    position: relative;

    &__wrapper {
    }

    &__title {
    }

    &__slider-container {
    }

    &__slider {
        width: 100%;
        height: 936px;
    }

    &__slider-wrapper {
    }

    &__slide {
        background-color: #ddd;
    }

    &__card {
    }

    &__nav {
    }

    &__next {
    }

    &__pagination {
    }
}

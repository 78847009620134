::-webkit-resizer {
    background-repeat: no-repeat;
    width: 10px;
    height: 0
}

::-webkit-scrollbar {
    width: 10px
}

::-webkit-scrollbar-button {
    background-repeat: no-repeat;
    width: 5px;
    height: 0
}

::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: $color3;
    -webkit-transition: .5s;
    transition: .5s
}

::-webkit-scrollbar-track {
    background-color: $bg-color;
}
.header-home {
    position: relative;

    &__title {
        font-family: $font3;
        font-weight: 900;
        font-size: 70px;
        line-height: 128%;
        text-transform: uppercase;
        color: $color1;
        margin-bottom: 30px;
        
        max-width: 786px;

        margin-top: 170px;
    }

    &__subtitle {
        font-weight: 400;
        font-size: 28px;
        line-height: 150%;
        letter-spacing: 0.02em;
        color: $color1;
        margin-bottom: 40px;
        max-width: 560px;
    }

    &__btn {
        padding: 20px 80px;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
    }

    &__wave {
        position: absolute;
        width: 100%;
        top: 30px;
        left: 0;
        z-index: 1;

        svg {
            width: 100%;
        }
    }
}
.header-home-top {
    padding-top: 60px;
    position: relative;
    z-index: 2;

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__col {
    }

    &__logo {
        font-family: $font3;
        font-weight: 900;
        //font-size: 20px;
        text-transform: uppercase;
        color: $color1;
        //text-decoration: none;

        span {
            //transition: color .2s;
        }

        &:hover {
            span {
                //color: $color2;
            }
        }

        .link-transformer__title {
            font-size: 20px;
            line-height: 20px;
        }

        .link-transformer__wrapper {
            height: 20px;
        }

        .link-transformer__secondary {
            transform: rotate(20deg);
        }

        &.link-transformer:hover {
            .link-transformer__inner {
                transform: translateY(-20px);
            }
        }
    }

    &__nav {
    }

    &__menu {
        display: flex;
        align-items: center;
        gap: 30px;
    }

    &__menu-item {
        
    }

    &__menu-link {
        position: relative;
        font-weight: 400;
        font-size: 18px;
        //line-height: 167%;
        color: $color1;
        padding: 10px;
        text-decoration: none;

        &::before {
            /**
            content: '';
            position: absolute;
            width: 0;
            height: 1px;
            background-color: #5e9ee7;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, -4px);
            box-shadow: 0 0 10px rgb(60 97 228);
            filter: blur(1px);
            transition: width 0.2s ease;
            */
        }

        &:hover {
            &::before {
                //width: calc(100% - 20px);
            }
        }

        .link-transformer__title {
            font-size: 18px;
            line-height: 18px;
        }

        .link-transformer__wrapper {
            height: 18px;
        }

        .link-transformer__secondary {
            transform: rotate(18deg);
        }

        &.link-transformer:hover {
            .link-transformer__inner {
                transform: translateY(-18px);
            }
        }
    }

    &__contacts {
    }

    &__contacts-item {
        font-family: $font3;
        font-weight: 900;
        //font-size: 20px;
        letter-spacing: 0.04em;
        //text-transform: uppercase;
        color: $color1;
        //text-decoration: none;
    }

    &__contacts-whatsapp {
        .link-transformer__title {
            font-size: 18px;
            line-height: 18px;
        }

        .link-transformer__wrapper {
            height: 18px;
        }

        .link-transformer__secondary {
            transform: rotate(18deg);
        }

        &.link-transformer:hover {
            .link-transformer__inner {
                transform: translateY(-18px);
            }
        }
    }
}

.header-home-body {
    position: relative;
    z-index: 2;

    &__wrapper {
        display: flex;
        justify-content: center;
    }

    &__col {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

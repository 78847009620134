.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 320px) {
    .container {
        max-width: 300px;
    }
}

@media (min-width: 480px) {
    .container {
        max-width: 460px;
    }
}

@media (min-width: 640px) {
    .container {
        max-width: 620px;
    }
}

@media (min-width: 960px) {
    .container {
        max-width: 940px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1160px;
    }
}

@media (min-width: 1350px) {
    .container {
        max-width: 1290px;
    }
}

@media (min-width: 1740px) {
    .container {
        max-width: 1680px;
    }
}

@media (min-width: 320px) and (max-width: 479px) {
    .container {
        max-width: 100%;
        padding: 0 20px;
    }
}

@media (min-width: 1200px) and (max-width: 1350px) {
}

@media (min-width: 960px) and (max-width: 1199px) {
}

@media (min-width: 640px) and (max-width: 959px) {
}

@media (min-width: 480px) and (max-width: 639px) {
}

@media (min-width: 320px) and (max-width: 479px) {
}

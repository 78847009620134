.about-section {
    margin-top: 130px;
    margin-bottom: 140px;

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        background-color: $color3;
        border-radius: $basicBr;
    }

    &__col {
        width: 50%;
    }

    &__examples {
    }

    &__example-row {
    }

    &__example-img-container {
    }

    &__example-img {
    }

    &__content {
        padding: 60px 30px;
    }

    &__title {
        color: $color1;
        margin-bottom: 12px;
    }

    &__logo-container {
    }

    &__logo {
        margin-bottom: 15px;
    }

    &__desc {
        color: $color1;
        opacity: 0.8;
        margin-bottom: 20px;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.4px;

        p {
            &:not(:last-of-type) {
                margin-bottom: 15px;
            }
        }
    }

    &__triggers {
        display: flex;
        gap: 40px;
        flex-wrap: wrap;
    }

    &__trigger {
        width: calc(50% - 20px);
        background-color: $color4;
        display: flex;
        padding: 28px 35px;
        align-items: center;
        border-radius: $basicBr;
        color: $color1;
        font-size: 18px;
    }

    &__trigger-img {
        margin-right: 15px;
    }
}

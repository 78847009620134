@import "vars";
@import "reset";
@import "mixins";

@import "../fonts/Roboto/stylesheet.css";
@import "../fonts/Lato/stylesheet.css";
@import "../fonts/Montserrat/stylesheet.css";

body {
    font-family: $font1;
    font-size: 14px;
    background-color: $bg-color;
}

main {
    position: relative;
    overflow: hidden;
}

.title {
    font-family: $font3;
    color: $color1;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
    text-align: center;
    text-transform: uppercase;
}

.subtitle {
    color: $color1;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 50px;
    text-align: center;
}

.btn {
    padding: 10px;
    background-color: #ddd;
    outline: none;
    cursor: pointer;
}

.btn-blue {
    border: 2px $color2 solid;
    background-color: $color2;
    border-radius: 12px;
    color: $color1;
    padding: 10px 20px;
}

.wave-mirror {
    transform: scaleX(-1);
}

/** defaults */
.link {
    text-decoration: none;
    cursor: pointer;
}

.uppercase {
    text-transform: uppercase;
}

.link-transformer {
    &__wrapper {
        display: inline-block;
        position: relative;
        height: 16px;
        overflow: hidden;
    }

    &__inner {
        transition: transform 0.4s ease;
    }

    &__title {
        display: block;
        font-size: 16px;
        line-height: 16px;
        transition: transform 0.4s ease;
    }

    &__primary {
        transform-origin: right center;
    }

    &__secondary {
        transform-origin: left center;
        transform: rotate(16deg);
    }

    &:hover &__inner {
        transform: translateY(-16px);
    }

    &:hover &__primary {
        transform: rotate(20deg);
    }

    &:hover &__secondary {
        transform: rotate(0);
    }
}

@import "grid";

@import "./components/site-scrollbar";
@import "./components/animations";

@import "./partials/header-home";
@import "./partials/about-section";
@import "./partials/home-services";
@import "./partials/home-projects";
@import "./partials/home-testimonials";
@import "./partials/home-feedback";
@import "./partials/footer";

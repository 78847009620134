.home-services {
    margin-bottom: 140px;
    position: relative;

    &__figure {
        width: 2300px;
        height: 1850px;
        transform: rotate(-45deg);
        flex-shrink: 0;
        background: radial-gradient(
            26.9% 38.96% at 50.99% 38.63%,
            rgba(129, 85, 255, 0.18) 0,
            rgba(129, 85, 255, 0) 100%
        );
        position: absolute;
        top: -500px;
        right: -800px;
        z-index: -1;
    }

    &__wrapper {
        position: relative;
    }

    &__title {
    }

    &__subtitle {
    }

    &__cards {
        display: flex;
        flex-wrap: wrap;
        gap: 60px;
        justify-content: center;
    }

    &__card {
        position: relative;
        width: calc(424px - 30px);
        background-color: $color3;
        border-radius: $basicBr;
        cursor: pointer;
        text-decoration: none;
        overflow: hidden;

        &:hover {
            //background: linear-gradient(145deg, #5E9EE7 9.43%, #3678DC 82.57%);
            &::before {
                opacity: 1;
            }
        }

        &::before {
            content: "";
            position: absolute;
            z-index: 1;
            background: linear-gradient(145deg, #5e9ee7 9.43%, #3678dc 82.57%);
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity 0.2s ease;
        }
    }

    &__card-wrapper {
        padding: 65px;
        position: relative;
        z-index: 2;
    }

    &__card-icon-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;
    }

    &__card-icon {
        margin-bottom: 20px;
    }

    &__card-icon-shadow {
        width: 212px;
        height: 22px;
        transform: rotate(5.5deg);
        background: radial-gradient(50% 50% at 50% 50%, #071b39 0%, rgba(28, 58, 101, 0) 100%);
        opacity: 0.5;
        filter: blur(10px);
    }

    &__card-name {
        font-family: $font3;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 20px;
        color: $color1;
        text-align: center;
    }

    &__card-desc {
        color: $color1;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
    }
}
